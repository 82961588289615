import React, { useState, useEffect } from "react";
import { Modal, Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "500px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  textAlign: "center",
  borderRadius: theme.shape.borderRadius,
}));

const CloseButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  minWidth: "auto",
  padding: theme.spacing(0.5),
}));

const AdvertisementModal = () => {
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="advertisement-modal"
      aria-describedby="advertisement-description"
    >
      <ModalContent>
        <CloseButton onClick={handleClose} aria-label="close">
          X
        </CloseButton>
        <Typography variant="h5" component="h2" gutterBottom>
          Welcome to Our Warehouse Management System!
        </Typography>
        <Typography variant="body1">
          Discover efficient Product management.
        </Typography>
      </ModalContent>
    </Modal>
  );
};

export default AdvertisementModal;
