import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Paper,
  Button,
  Typography,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import BarcodeProductPage from "./BarcodeProductPage";
import ProductList from "./ProductList";
import AdvertisementModal from "./AdvertisementModal";
import Advertisement from "./Advertisement";
import TabPanel from "../TabPanel";
import { useLogout } from "../../utils/useLogout";

const PageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
  padding: theme.spacing(3),
  position: "relative",
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "& > :first-of-type": {
    marginBottom: theme.spacing(1), // Add margin below the first child (WMS)
  },
}));

const UserContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
});

const UserInfoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginTop: 1,
});

const LogoutButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.grey[400],
  },
}));

const ContentContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const ProductManagement = () => {
  const [tabValue, setTabValue] = useState(0);
  const [userNickname, setUserNickname] = useState("");
  const handleLogout = useLogout();

  useEffect(() => {
    const nickname = sessionStorage.getItem("user_nickname");
    setUserNickname(nickname || "User");
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <PageContainer>
      <Header>
        <TitleContainer>
          <Typography variant="h5">WMS</Typography>
          <Typography variant="subtitle1">Product Management</Typography>
        </TitleContainer>
        <UserContainer>
          <LogoutButton
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleLogout}
          >
            Logout
          </LogoutButton>
          <UserInfoContainer>
            <Avatar sx={{ marginRight: 1, width: 30, height: 30 }}>
              {userNickname[0]}
            </Avatar>
            <Typography variant="subtitle2">{userNickname}</Typography>
          </UserInfoContainer>
        </UserContainer>
      </Header>
      <AdvertisementModal />
      <ContentContainer>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="product management tabs"
          >
            <Tab label="Register" />
            <Tab label="List" />
            <Tab label="Ad" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <BarcodeProductPage />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ProductList />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Advertisement />
        </TabPanel>
      </ContentContainer>
    </PageContainer>
  );
};

export default ProductManagement;
