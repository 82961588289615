import React, { useEffect } from "react";
import { Box, Typography, CircularProgress, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import SPRING_API_URL from "../../utils/SpringApiUrl";
import kakaoLoginButton from "../../assets/kakaoLoginButton.png";
import axios from "axios";

const LoginContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  backgroundColor: theme.palette.background.default,
}));

const LoginBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  width: "300px",
  textAlign: "center",
}));

const KakaoButton = styled("img")({
  cursor: "pointer",
  width: "200px",
  marginTop: "20px",
  "&:hover": {
    opacity: 0.8,
  },
});

const KakaoLoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const code = new URLSearchParams(location.search).get("code");
    if (code) {
      handleKakaoCallback(code);
    }
  }, [location]);

  const handleLogin = async () => {
    try {
      const config = {
        method: "get",
        url: `${SPRING_API_URL}/oauth2/kakao`,
      };

      const response = await axios.request(config);
      const kakaoLoginUrl = response.data;
      window.location.href = kakaoLoginUrl;
    } catch (error) {
      console.error("Error initiating Kakao login:", error);
    }
  };

  const handleKakaoCallback = async (code) => {
    try {
      const config = {
        method: "get",
        url: `${SPRING_API_URL}/oauth2/kakao/callback`,
        params: { code }, // 쿼리 파라미터는 `params` 속성으로 전달
      };

      const response = await axios.request(config);
      const data = response.data; // axios는 기본적으로 JSON 응답을 처리함

      // 토큰을 세션 스토리지에 저장
      sessionStorage.setItem("access_token", data.accessToken);
      sessionStorage.setItem("refresh_token", data.refreshToken);
      sessionStorage.setItem("user_id", data.id);
      sessionStorage.setItem("user_nickname", data.nickname);

      // React 앱의 루트 경로로 리다이렉트
      navigate("/");
    } catch (error) {
      console.error("Error during Kakao callback:", error);
      // 에러 처리 (예: 에러 페이지로 리다이렉트 또는 에러 메시지 표시)
    }
  };

  if (location.search.includes("code=")) {
    return (
      <LoginContainer>
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: "1rem" }}>
          로그인 처리 중...
        </Typography>
      </LoginContainer>
    );
  }

  return (
    <LoginContainer>
      <LoginBox elevation={3}>
        <Typography variant="h5" gutterBottom>
          Welcome to Product Management System
        </Typography>
        <KakaoButton
          src={kakaoLoginButton}
          alt="Login with Kakao"
          onClick={handleLogin}
        />
      </LoginBox>
    </LoginContainer>
  );
};

export default KakaoLoginPage;
