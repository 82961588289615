import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgress, Typography, Box } from "@mui/material";

const OAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("accessToken");
    const refreshToken = params.get("refreshToken");
    const id = params.get("id");
    const nickname = params.get("nickname");

    if (accessToken && refreshToken && id && nickname) {
      sessionStorage.setItem("access_token", accessToken);
      sessionStorage.setItem("refresh_token", refreshToken);
      sessionStorage.setItem("user_id", id);
      sessionStorage.setItem("user_nickname", nickname);

      navigate("/");
    } else {
      navigate("/login", {
        state: { error: "Login failed. Please try again." },
      });
    }
  }, [location, navigate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <CircularProgress />
      <Typography variant="body1" style={{ marginTop: "1rem" }}>
        처리 중...
      </Typography>
    </Box>
  );
};

export default OAuthCallback;
