import React, { useState, useMemo } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { useTable, useSortBy } from "react-table";
import * as XLSX from "xlsx";
import SPRING_API_URL from "../../utils/SpringApiUrl";

const SearchContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

const ProductList = () => {
  const defaultSearchParams = {
    from: new Date().toISOString().split("T")[0],
    to: new Date().toISOString().split("T")[0],
    category: "",
    barcode: "",
  };

  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [products, setProducts] = useState([]);

  const handleParamChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = async () => {
    const accessToken = sessionStorage.getItem("access_token");
    const config = {
      method: "post",
      url: `${SPRING_API_URL}/register/product-list`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: searchParams,
    };
    try {
      const response = await axios.request(config);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching product list:", error);
    }
  };

  const handleReset = () => {
    setSearchParams(defaultSearchParams);
  };

  const handleClear = () => {
    setProducts([]);
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "productInfoId",
      },
      {
        Header: "Barcode",
        accessor: "itBarcode",
      },
      {
        Header: "Name (KR)",
        accessor: "itName",
      },
      {
        Header: "Name (EN)",
        accessor: "enItName",
      },
      {
        Header: "Category",
        accessor: (row) =>
          `${row.caName || "N/A"} > ${row.caName2 || "N/A"} > ${
            row.caName3 || "N/A"
          } > ${row.caName4 || "N/A"}`,
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: products,
      },
      useSortBy
    );

  const handleExcelDownload = () => {
    const ws = XLSX.utils.json_to_sheet(products);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Products");

    const now = new Date();
    const formattedDate =
      now.getFullYear().toString() +
      (now.getMonth() + 1).toString().padStart(2, "0") +
      now.getDate().toString().padStart(2, "0") +
      "_" +
      now.getHours().toString().padStart(2, "0") +
      now.getMinutes().toString().padStart(2, "0") +
      now.getSeconds().toString().padStart(2, "0");

    const fileName = `wms_${formattedDate}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Product List
      </Typography>
      <SearchContainer>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <Box display="flex" gap={1}>
              <TextField
                label="From Date"
                name="from"
                type="date"
                value={searchParams.from}
                onChange={handleParamChange}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="To Date"
                name="to"
                type="date"
                value={searchParams.to}
                onChange={handleParamChange}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Category"
              name="category"
              value={searchParams.category}
              onChange={handleParamChange}
              fullWidth
              variant="outlined"
              placeholder="Search in all category levels"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Barcode"
              name="barcode"
              value={searchParams.barcode}
              onChange={handleParamChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <ButtonContainer>
              <Box display="flex" gap={1}>
                <Button
                  variant="outlined"
                  onClick={handleReset}
                  fullWidth
                  size="small"
                >
                  Reset
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleClear}
                  fullWidth
                  size="small"
                >
                  Clear
                </Button>
              </Box>
              <Box display="flex" gap={1}>
                <Button variant="contained" onClick={handleSearch} fullWidth>
                  Search
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleExcelDownload}
                  fullWidth
                  disabled={products.length === 0}
                >
                  Excel
                </Button>
              </Box>
            </ButtonContainer>
          </Grid>
        </Grid>
      </SearchContainer>
      <TableContainer component={Paper} elevation={0}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProductList;
