import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import setupAxiosInterceptors from "./utils/axiosConfig";
import "bootstrap/dist/css/bootstrap.min.css";

setupAxiosInterceptors();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
