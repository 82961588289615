import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import KakaoLoginPage from "../components/login/KakaoLoginPage";
import ProductManagement from "../components/Product/ProductManagement";
import { ProtectedRoute } from "../utils/authUtils";
import OAuthCallback from "./login/OAuthCallback";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<KakaoLoginPage />} />
        <Route path="/oauth/kakao/callback" element={<OAuthCallback />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <ProductManagement />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
