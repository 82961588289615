import React from "react";
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import phoneIcon from "../../styles/images/phone_icon_48.png";
import emailIcon from "../../styles/images/email_icon_48.png";

const AdvertisementContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const IconImg = styled("img")({
  width: 24,
  height: 24,
  flexShrink: 0,
});

const BulletList = styled(Typography)(({ theme }) => ({
  "& ul": {
    listStyleType: "none",
    paddingLeft: theme.spacing(0),
    marginTop: 0,
    marginBottom: 0,
    "& li": {
      position: "relative",
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
      "&::before": {
        content: '"•"',
        position: "absolute",
        left: 0,
        color: theme.palette.primary.main,
      },
    },
  },
}));

const ResponsiveListItem = styled(ListItem)(({ theme }) => ({
  flexWrap: "wrap",
  "& .MuiListItemIcon-root": {
    minWidth: "auto",
    marginRight: theme.spacing(1),
  },
  "& .MuiListItemText-root": {
    flex: "1 1 auto",
    wordBreak: "break-word",
  },
}));

const Advertisement = () => {
  return (
    <AdvertisementContainer>
      <Typography variant="h6" gutterBottom>
        About Our WMS - Product Management System
      </Typography>
      <Typography variant="body1" paragraph>
        Our Product Management System streamlines your inventory processes,
        allowing you to efficiently register new products and manage your
        existing catalog.
      </Typography>
      <Section>
        <Typography variant="h6" gutterBottom color="primary">
          Key Features
        </Typography>
        <BulletList variant="body1" component="div">
          <ul>
            <li>Easy barcode scanning for quick product registration</li>
            <li>Comprehensive product list management</li>
            <li>User-friendly interface for seamless operation</li>
          </ul>
        </BulletList>
      </Section>

      <Section>
        <Typography variant="h6" gutterBottom color="primary">
          Contact Information
        </Typography>
        <List>
          <ResponsiveListItem>
            <ListItemIcon>
              <IconImg src={phoneIcon} alt="Phone icon" />
            </ListItemIcon>
            <ListItemText primary="+82 10-3729-4567" />
          </ResponsiveListItem>
          <ResponsiveListItem>
            <ListItemIcon>
              <IconImg src={emailIcon} alt="Email icon" />
            </ListItemIcon>
            <ListItemText primary="kimch@monorama.kr" />
          </ResponsiveListItem>
        </List>
      </Section>
    </AdvertisementContainer>
  );
};

export default Advertisement;
