import "./App.scss";
import PageRoutes from "./components/PageRoutes";
import BrowserRedirect from "./components/BrowserRedirect";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.innerHeight < window.innerWidth) {
        alert("가로 모드 사용을 지원하지 않습니다. 기기를 세로로 돌려주세요.");
        // 페이지를 새로 고침 하거나 원하는 동작 수행
        // window.location.reload(); // 새로고침
      }
    };

    // 초기 호출
    handleOrientationChange();

    // 리사이즈 이벤트 리스너 추가
    window.addEventListener("resize", handleOrientationChange);

    return () => {
      // 클린업: 리스너 제거
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRedirect>
        <PageRoutes />
      </BrowserRedirect>
    </div>
  );
}

export default App;
