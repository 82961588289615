import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import addIcon from "../assets/add_icon_48.png";
import shareIcon from "../assets/share_icon_48.png";

const BrowserRedirect = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    message: "",
    action: "",
    isAppropriate: false,
  });
  const [skipChecked, setSkipChecked] = useState(false);

  useEffect(() => {
    const checkBrowserAndOS = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isIOS = /iphone|ipad|ipod/.test(userAgent);
      const isAndroid = /android/.test(userAgent);
      const isSafari =
        /safari/.test(userAgent) &&
        !/chrome/.test(userAgent) &&
        !/crios/.test(userAgent);
      const isChrome = /chrome/.test(userAgent) || /crios/.test(userAgent);
      const isPC = !isIOS && !isAndroid;

      const currentUrl = window.location.href;
      const installStatus = localStorage.getItem("appInstallStatus");
      const skipMessage = localStorage.getItem("skipInstallMessage");
      const wrongBrowserSkipMessage = localStorage.getItem(
        "wrongBrowserSkipMessage"
      );

      if (
        installStatus === "yes" ||
        skipMessage === "true" ||
        wrongBrowserSkipMessage === "true"
      ) {
        return; // Don't show modal if user has installed or chosen to skip
      }

      if (isIOS) {
        if (!isSafari) {
          setModalContent({
            title: "Safari 사용 안내",
            message:
              "Safari에서는 홈 화면에 앱을 쉽게 추가할 수 있습니다. Safari로 이동하여 더 나은 경험을 누려보세요.",
            action: `Safari에서 열기: ${currentUrl}`,
            isAppropriate: false,
          });
          setShowModal(true);
        } else {
          setModalContent({
            title: "홈 화면 추가 안내",
            message: `
            홈 화면에 추가하여 앱처럼 사용할 수 있습니다.<br><br>
            
            1. 하단의 '공유 <img src="${shareIcon}" alt="공유" style="height: 20px; vertical-align: middle;" />' 버튼을 탭<br>
            2. 스크롤을 내려 '홈 화면에 추가 <img src="${addIcon}" alt="추가" style="height: 20px; vertical-align: middle;" />'를 선택<br>
            3. '추가'를 탭하여 완료<br>
            4. 홈 화면에 생성 되었는지 확인 후 실행
          `,
            action: "",
            isAppropriate: true,
          });
          setShowModal(true);
        }
      } else if (isAndroid) {
        if (!isChrome) {
          setModalContent({
            title: "Chrome 사용 안내",
            message:
              "Chrome에서는 홈 화면에 앱을 쉽게 추가할 수 있습니다. Chrome으로 이동하여 더 나은 경험을 누려보세요.",
            action: `Chrome에서 열기: ${currentUrl}`,
            isAppropriate: false,
          });
          setShowModal(true);
        } else {
          setModalContent({
            title: "홈 화면 추가 안내",
            message: `
            홈 화면에 추가하여 앱처럼 사용할 수 있습니다.<br><br>
            
            1. 상단의 메뉴(⋮)를 탭<br>
            2. '홈 화면에 추가 <img src="${addIcon}" alt="추가" style="height: 20px; vertical-align: middle;" />'를 선택<br>
            3. '추가'를 탭하여 완료<br>
            4. 홈 화면에 생성 되었는지 확인 후 실행
          `,
            action: "",
            isAppropriate: true,
          });
          setShowModal(true);
        }
      }
      // else if (isPC) {
      //   if (!isChrome) {
      //     setModalContent({
      //       title: "브라우저 안내",
      //       message: "최적의 경험을 위해 Chrome을 사용해주세요.",
      //       action: `Chrome에서 열기: ${currentUrl}`,
      //       isAppropriate: false,
      //     });
      //     setShowModal(true);
      //   } else {
      //     setModalContent({
      //       title: "바로가기 추가 안내",
      //       message: `
      //               Chrome 메뉴에서 '더보기 도구 <img src="${shareIcon}" alt="공유" style="height: 20px; vertical-align: middle;" /> > 바로가기 만들기'를 선택하여 바로가기를 추가하면 더 편리하게 이용하실 수 있습니다.
      //           `,
      //       action: "",
      //       isAppropriate: true,
      //     });
      //     setShowModal(true);
      //   }
      // }
    };

    checkBrowserAndOS();
  }, []);

  const handleAction = () => {
    if (
      modalContent.action.startsWith("Chrome에서 열기:") ||
      modalContent.action.startsWith("Safari에서 열기:")
    ) {
      const url = modalContent.action.split(": ")[1];
      navigator.clipboard
        .writeText(url)
        .then(() =>
          alert(
            "링크가 클립보드에 복사되었습니다. 권장 브라우저에서 붙여넣기 하세요."
          )
        )
        .catch((err) => console.error("클립보드 복사 실패:", err));
    }
    if (skipChecked) {
      localStorage.setItem("wrongBrowserSkipMessage", "true");
    }
    setShowModal(false);
  };

  const handleResponse = (response) => {
    if (response === "yes") {
      localStorage.setItem("appInstallStatus", "yes");
    } else {
      localStorage.setItem("appInstallStatus", "no");
    }
    if (skipChecked) {
      localStorage.setItem("skipInstallMessage", "true");
    }
    setShowModal(false);
  };
  //
  const handleSkipChange = (e) => {
    setSkipChecked(e.target.checked);
    if (e.target.checked) {
      if (modalContent.isAppropriate) {
        localStorage.setItem("skipInstallMessage", "true");
      } else {
        localStorage.setItem("wrongBrowserSkipMessage", "true");
      }
      setShowModal(false);
    }
  };

  const headerStyle = {
    backgroundColor: "#e2e2e2",
    borderBottom: "1px solid #dee2e6",
    padding: "1rem",
    position: "relative",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "0.5rem",
    right: "1rem",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#000",
    opacity: "0.5",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
  };
  return (
    <>
      {children}
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        fade={false}
        centered
      >
        <ModalHeader
          toggle={() => setShowModal(false)}
          close={
            <button
              type="button"
              style={closeButtonStyle}
              onClick={() => setShowModal(false)}
            >
              &times;
            </button>
          }
          style={headerStyle}
        >
          {modalContent.title}
        </ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: modalContent.message }} />
          {!modalContent.isAppropriate && modalContent.action && (
            <div className="text-center mt-3">
              <button className="btn btn-primary" onClick={handleAction}>
                링크 복사
              </button>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="w-100">
            {modalContent.isAppropriate && (
              <div className="d-flex justify-content-end mb-3">
                <button
                  className="btn btn-secondary me-2"
                  onClick={() => handleResponse("no")}
                >
                  아니오
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => handleResponse("yes")}
                >
                  예
                </button>
              </div>
            )}
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="skipMessage"
                checked={skipChecked}
                onChange={handleSkipChange}
              />
              <label className="form-check-label" htmlFor="skipMessage">
                이 메시지 다시 보지 않기
              </label>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BrowserRedirect;
