import { useNavigate } from "react-router-dom";
import axios from "axios";
import SPRING_API_URL from "./SpringApiUrl";

export const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const token = sessionStorage.getItem("access_token");
      if (!token) {
        console.error("No access token found");
        navigate("/login");
        return;
      }

      await axios.post(
        `${SPRING_API_URL}/oauth2/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      clearSessionStorage();
      navigate("/login");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error("Logout failed with status:", error.response.status);
          if (error.response.status === 401) {
            clearSessionStorage();
            navigate("/login");
          } else {
            console.error(
              "Unexpected error during logout:",
              error.response.data
            );
          }
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up the request:", error.message);
        }
      } else {
        console.error("Non-Axios error during logout:", error);
      }
    }
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("user_nickname");
  };

  return handleLogout;
};
